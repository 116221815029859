import React from 'react'
import { Link } from 'gatsby'
import './orgRow.scss'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import OrgCard from '../OrgCard/orgCard'
import { Container, Col, Row, Spinner } from 'reactstrap'

import OrgIcon from '../../assets/graphics/graphic-org.svg'
import LeftArrow from '../../assets/graphics/graphic-left-arrow.svg'
import RightArrow from '../../assets/graphics/graphic-right-arrow.svg'

const OrgRow = props => {
	const MenuItem = ({ org }) => {
		return (
			<div className={`org-row-card`}>
				<Link to={`/org/?id=${org.id}`} alt='' className='card-link'>
					<OrgCard name={org.name} logoUrl={org.logoUrl} />
				</Link>
			</div>
		)
	}

	const Menu = orgs =>
		orgs.map(org => {
			return <MenuItem org={org} key={org.id} />
		})

	const Arrow = ({ src, className }) => {
		return (
			<div className={className}>
				<img src={src} alt='' />
			</div>
		)
	}
	const ArrowLeft = Arrow({ src: LeftArrow, className: 'arrows' })
	const ArrowRight = Arrow({ src: RightArrow, className: 'arrows' })

	const menu = Menu(props.orgData)

	const isLoading = props => {
		if (props.status === true) {
			return (
				<div style={{ textAlign: 'center', color: '#8345f8' }}>
					<Spinner animation='grow' role='status' />
				</div>
			)
		}

		return <ScrollMenu data={menu} arrowLeft={ArrowLeft} arrowRight={ArrowRight} inertiaScrolling translate={3} wheel={false} />
	}

	return (
		<Container className='pt-0 mt-5'>
			<Row>
				<Col md={2} className='text-center'>
					<img src={OrgIcon} alt='' />
				</Col>
				<Col md={10} className='mb-3'>
					<Row className='org-row-title'>
						Live Feed From Patients
						{/* <span className='org-row-count pl-3'>2.1k Carepostcards</span> */}
					</Row>
					<Row>
						<span className='org-row-subtitle pt-2'>
							Carepostcards sent by patients while being cared for in hospitals and healthcare organizations enabled by{' '}
							<a href='https://www.wambi.org' target='_blank' rel='noopener noreferrer'>
								Wambi
							</a>
							.
						</span>
					</Row>
				</Col>
			</Row>
			{isLoading(props)}
		</Container>
	)
}

export default OrgRow
