import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Container, Row, Col, Button } from 'reactstrap'

import './index.scss'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CareRowLatest from '../components/CareRow/Latest'
import CareRowHashtag from '../components/CareRow/Hashtag'
import CareRowAllWorkers from '../components/CareRow/AllWorkers'
import OrgRow from '../components/OrgRow/orgRow'

// import Squiggly from '../assets/graphics/graphic-squiggly.svg'
import VideoThumbnail from '../assets/images/image-video-thumbnail.png'

import { fetchFeaturedHashtags } from '../actions/hashtagActions'

const IndexPage = () => {
	const [orgs, setOrgs] = useState([])
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()
	const hashtags = useSelector(state => state.hashtags.hashtags)
	const [userId, setUserId] = useState(0)

	useEffect(() => {
		dispatch(fetchFeaturedHashtags())
	}, [dispatch])

	useEffect(() => {
		const userId = window.localStorage.getItem('userId')
		setUserId(userId)

		async function fetchData() {
			try {
				const orgsResult = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/orgs/featured`)
				setOrgs(orgsResult.data.orgs.slice(1))
				setLoading(false)
			} catch (error) {
				console.error('Error getting card data: ', error)
			}
		}
		fetchData()
	}, [])

	return (
		<Layout>
			<SEO title='Home' />
			<Container className='mb-5'>
				<Row>
					<Col></Col>
					<Col lg={5} className='p-3 pr-4'>
						{/* <Row className='home-page-main-title'>Moments move us.</Row>
							<Row>
								<img src={Squiggly} alt='Squiggly' />
							</Row>
							<Row className='home-page-subtitle mt-0'>
								There has never been a more important time to share gratitude with nurses, doctors, and other healthcare workers
								battling COVID-19. Carepostcard allows you to meaningfully honor those on the frontlines by sharing your stories and
								thanks.
								<br />
								<br />
								Carepostcard combines real-time gratitude from patients in hospitals and healthcare organizations with appreciation
								from the public to create a centralized hub to uplift, inspire, and further the Compassionate Care Movement. Whether
								it was a passing moment or a major event, share your voice with a Carepostcard.
							</Row> */}
						<Row className='home-page-main-title mb-3'>Thank a healthcare hero today!</Row>
						<Row className='home-page-subtitle mt-0'>
							As the world settles into the reality of COVID-19 for the long haul, seeing nurses, doctors, and other healthcare workers
							give tirelessly to those in need has become part of “the new normal.” But there is nothing normal about their courage,
							compassion, and grit—that’s why it is more important than ever to share your gratitude with our healthcare heroes. With
							Carepostcard, you can thank a nurse who cared for you, or shout words of encouragement to healthcare workers across the
							country.
							<br />
							<br />
							Carepostcard combines real-time gratitude from patients in hospitals and healthcare organizations with appreciation from
							you, the public, to create a centralized hub to uplift and inspire our frontline heroes. Whether it was a passing moment
							or a major event, share your voice with a Carepostcard.
						</Row>
						<Row>
							<Button className='home-page-create-button ml-0 mt-4' href='/create'>
								Create a CarePostcard
							</Button>
						</Row>
					</Col>
					<Col lg={5} className='text-center'>
						<video controls poster={VideoThumbnail} className='home-page-video-preview'>
							<source src='https://carepostcard-env-development.s3.amazonaws.com/carepostcard-intro.mp4' type='video/mp4' />
						</video>
					</Col>
					<Col></Col>
				</Row>
				<OrgRow orgData={orgs} status={loading} />
				<CareRowAllWorkers />
				<CareRowLatest />
				{hashtags
					// .sort((a, b) => b.cardcount - a.cardcount)
					.map(tag => {
						return <CareRowHashtag key={tag.id} tagId={tag.id} />
					})}
			</Container>
		</Layout>
	)
}

export default IndexPage
