import React from 'react'
import PropTypes from 'prop-types'

import LeftArrowImage from '../../assets/graphics/graphic-left-arrow.svg'
import RightArrowImage from '../../assets/graphics/graphic-right-arrow.svg'

const Arrow = ({ src, className }) => {
	return (
		<div className={className}>
			<img src={src} alt='arrow icon' />
		</div>
	)
}

Arrow.propTypes = {
	src: PropTypes.string,
	className: PropTypes.string,
}

const ArrowLeft = <Arrow src={LeftArrowImage} className={'arrows'} />

const ArrowRight = <Arrow src={RightArrowImage} className={'arrows'} />

export { ArrowLeft, ArrowRight, Arrow }
