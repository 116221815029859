import React from 'react'
import PropTypes from 'prop-types'

import CareCard from '../CareCard/careCard'

const CareRowItem = ({ post }) => {
	return (
		<div key={post.id} className={'care-row-card'}>
			{/* <a href={`/detail/?id=${post.id}`} alt='' className='card-link'> */}
			<CareCard post={post} />
			{/* </a> */}
		</div>
	)
}

CareRowItem.propTypes = {
	post: PropTypes.object.isRequired,
}

export default CareRowItem
