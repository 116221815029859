import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'

import './orgCard.scss'

const OrgCard = props => {
	return (
		<Card className='org-card'>
			<CardBody className='org-card-body'>
				<img alt='Org' src={props.logoUrl} className='org-card-image mb-2' />
				<CardTitle className='org-card-title pb-2'>{props.name}</CardTitle>
			</CardBody>
		</Card>
	)
}

export default OrgCard
