import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { Container, Row, Col, Button, Spinner } from 'reactstrap'

import './careRow.scss'

import CareRowItem from './Item'
import { ArrowLeft, ArrowRight } from './Arrow'

import NurseHash from '../../assets/graphics/graphic-nurse.svg'
import DoctorHash from '../../assets/graphics/graphic-doctor.svg'
import FrontlineHash from '../../assets/graphics/graphic-frontline.svg'
import ReachingHash from '../../assets/graphics/graphic-reaching.svg'
import GratefulHash from '../../assets/graphics/graphic-grateful.svg'
import AAPACN from '../../assets/graphics/graphic-aapacn.svg'

const cardImageMapping = {
	'#thankanurse': NurseHash,
	'#aapacn': AAPACN,
	'#thankadoctor': DoctorHash,
	'#gratefulpatient': GratefulHash,
	'#reachingourheroes': ReachingHash,
	'#frontlinefeels': FrontlineHash,
}

const CareRowHashtag = ({ tagId }) => {
	const tag = useSelector(({ hashtags }) => hashtags.hashtags.find(hashtag => hashtag.id == tagId))
	const [posts, setPosts] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const cardImage = cardImageMapping[tag.hashtag] || GratefulHash

	useEffect(() => {
		async function fetchPosts() {
			const url = `${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}`
			const hashtag = tag.hashtag.replace(/#/g, '')
			const postsResult = await axios.get(`${url}/cpcs/hashtag/${hashtag}/featured`)
			setPosts(postsResult.data.cpcs)
			setIsLoading(false)
		}
		fetchPosts()
	}, [tag])

	const menu = posts.map(post => <CareRowItem key={post.id} post={post} />)

	return (
		<Container className='pt-3 mt-5'>
			<Row>
				<Col md={2} className='text-center'>
					<img src={cardImage} alt='Hashtag Image' />
				</Col>
				<Col md={10} className='mb-3'>
					<Row className='care-row-title'>
						{
							<Link to={`/hashtag/?hashtag=${encodeURIComponent(tag.hashtag)}`} alt='' className='card-link'>
								{tag.title} {tag.hashtag}
								{/* <span className='care-row-count pl-3'>{tag.cardcount} Carepostcards</span> */}
							</Link>
						}
					</Row>
					<Row>
						<span className='care-row-subtitle pt-2'>{tag.desc}</span>
					</Row>
					<Row>
						<Button size='sm' className='care-row-create-button px-3' href={`/create/?hashtag=${tag.hashtag.replace('#', '')}`}>
							Post
						</Button>
					</Row>
				</Col>
			</Row>
			{isLoading ? (
				<div style={{ textAlign: 'center', color: '#8345f8' }}>
					<Spinner animation='grow' role='status' />
				</div>
			) : (
				<ScrollMenu data={menu} arrowLeft={ArrowLeft} arrowRight={ArrowRight} inertiaScrolling translate={3} wheel={false} />
			)}
		</Container>
	)
}

CareRowHashtag.propTypes = {
	tagId: PropTypes.number.isRequired,
}

export default CareRowHashtag
