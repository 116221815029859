import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { Container, Row, Col, Button, Spinner } from 'reactstrap'

import './careRow.scss'

import CareRowItem from './Item'
import { ArrowLeft, ArrowRight } from './Arrow'

import HealthcareImage from '../../assets/graphics/graphic-healthcare.png'

const CareRowAllWorkers = () => {
	const [posts, setPosts] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		async function fetchPosts() {
			const url = `${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}`
			const postsResult = await axios.get(`${url}/cpcs/org/19300`)
			setPosts(postsResult.data.cpcs)
			setIsLoading(false)
		}
		fetchPosts()
	}, [])

	const menu = posts.map(post => <CareRowItem key={post.id} post={post} />)

	return (
		<Container className='pt-3 mt-5'>
			<Row>
				<Col md={2} className='text-center'>
					<img src={HealthcareImage} alt='Healthcare' className='healthcare-image' />
				</Col>
				<Col md={10} className='mb-3'>
					<Row className='care-row-title'>
						{
							<Link to='/org/?id=19300' alt='' className='card-link'>
								Healthcare Workers
								{/* <span className='care-row-count pl-3'>2.1k Carepostcards</span> */}
							</Link>
						}
					</Row>
					<Row>
						<span className='care-row-subtitle pt-2'>
							The world is full of gratitude for healthcare workers, so we suggest you check here daily to read about moments that will
							make you smile.
						</span>
					</Row>
					<Row>
						<Button size='sm' className='care-row-create-button px-3' href={'/create/?org=19300'}>
							Post
						</Button>
					</Row>
				</Col>
			</Row>
			{isLoading ? (
				<div style={{ textAlign: 'center', color: '#8345f8' }}>
					<Spinner animation='grow' role='status' />
				</div>
			) : (
				<ScrollMenu data={menu} arrowLeft={ArrowLeft} arrowRight={ArrowRight} inertiaScrolling translate={3} wheel={false} />
			)}
		</Container>
	)
}

export default CareRowAllWorkers
